import { useNewHirePlans } from 'hooks/user-data'
import { useEffectOnceSessionStorageCondition } from 'hooks/utils'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { myProfileSelector, MyProfileUser } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { ldIdentify } from 'utils/launchdarkly/launch-darkly-utils'

/**
 * Adds "isNewHire" property to the LD User
 */
export const useUpdateLaunchDarklyIdentify = () => {
	const ldClient = useLDClient()
	const siteInfo: SiteInfoResponse = useSelector(siteInfoSelector)
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const { isFetched, isNewHire } = useNewHirePlans()

	useEffectOnceSessionStorageCondition(
		() => {
			send(isNewHire)
		},
		'ld-identify-new-hire',
		isFetched,
	)

	const send = (isNewHire: boolean) => {
		const { employeeId, employmentGroup, employmentType, userId } = userProfile
		ldIdentify(ldClient, siteInfo.organizationName, userId, employeeId, employmentGroup, employmentType, isNewHire)
	}
}
